
.Paginator.row {
  margin-left: 15px;
}

.nav-item.active .nav-link {
  color: $secondary;
  padding-bottom: calc(1rem - 11px);
}

.nav-item.active {
  border-bottom: 3px solid $mi-primary-color;
}

.table-bordered tr.border-top-3 th, .table-bordered tr.border-top-3 td {
  border-top: 4px solid #dee2e6 !important;
}

.row-border-top-3 {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 5px solid $grey-30;
}

.line-height-1 {
  line-height: 1;
}

.DenialReasonInformation {
  tbody th {
    border-bottom: 1px solid rgba(108, 117, 125, 0.35);
  }
}