
.BonaFideDenialYears {
  th {
    background: #fafafa !important; // .bg-light
  }

  thead tr:first-child > th {
    background: #fafafa !important;
  }

  tbody th {
    border-bottom: 2px solid #dee2e6 !important;
  }

  .table-responsive {
    border: 1px solid #DDD;
    border-radius: 0.25rem;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  }

  tr.BonaFideDenialYearsTotal {
    background: rgba(40, 128, 124, 0.1);
  }

  .table-responsive:last-child {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}

.BonaFideDenialYearFormRow {
  border-bottom: 1px solid #EEE;
  min-height: 63px;
}

.BonaFideDenialYearFormRow:last-child {
  border-bottom: none;
}

.BonaFideDenialYearFormRow:focus-within {
  background: rgba(40, 128, 124, 0.1);
}

.BonaFideDenialYearTotalRow {
  border-top: 2px solid #EEE;
}

.BonaFideDenialYearCard:nth-child(odd) {
  background: #F1F1F1 !important;
  border-top: 2px solid #DDD;
}

.BonaFideDenialYears:nth-child(odd) .table-responsive {
  border: 1px solid #CCC !important;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2) !important;
}

.BonaFideDenialYearsTotal {
  td {
    border-top: 2px solid #c4cdcd !important;
  }
}